import React from "react";
import signal from "../../assets/Signal Mock.png";
import signalMobile from "../../assets/signalMobile.png";
import { BsArrowRightShort } from "react-icons/bs";
import JoinBeta from "../JoinBeta";
import ModalService from "../Modal/ModalService";

function SupplyBusiness() {
  const joinBeta = () => {
    ModalService.open(JoinBeta)
  }
  const steps = [
    {
      id: 1,
      title: "Create An Account",
      desc: "To gain access to your dashboard, create a Crosspoint suppliers account.",
    },
    {
      id: 2,
      title: "Create A Wallet",
      desc: "Create a Crosspoint Wallet to receive payments when you fulfil orders",
    },
    {
      id: 3,
      title: "Add Products",
      desc: "Select the product you supply on your dashboard to start getting orders",
    },
    {
      id: 4,
      title: "Fulfil Your Orders",
      desc: "Fulfil your orders, confirm delivery and receive payments in your wallet",
    },
  ];
  return (
    <div className="mt-[120px] p-[20px] lg:p-[40px_60px]">
      <div className="mt-[72px] lg:mt-[32px] flex  flex-col-reverse lg:grid lg:grid-cols-2">
        <div className="flex flex-col mt-[60px] lg:mt-0">
          <div className="hidden lg:block">
            <p className="font-[400] text-[24px] lg:text-[56px] leading-[36px] lg:leading-[80px]  font-alegreya">
              Supply Our Businesses
            </p>
            <p className="font-nunito mt-[32px] text-[16px]">
              Join Crosspoint’s network of suppliers, sell to buying
              communities,
              <br className="hiddeb lg:block" /> process orders conveniently,
              track your sales, earn rewards and do{" "}
              <br className="hiddeb lg:block" /> much more.
            </p>
          </div>
          <div className="mt-[32px]">
            {steps.map(({ id, title, desc }) => (
              <div className="h-[152px] flex space-x-[32px] " key={id}>
                <div className="flex flex-col items-center justify-start w-fit">
                  <div className="h-[35px] w-[30px] rounded-full bg-[#CED6FF] flex items-center justify-center ">
                    {id}
                  </div>
                  {id !== 5 && (
                    <div className="w-[1px] h-[152px] bg-[#CED6FF]"> </div>
                  )}
                </div>
                <div className="flex flex-col space-y-[10px]">
                  <p className="font-[700] text-[16px] lg:text-[24px] font-nunito">
                    {title}
                  </p>
                  <p className="font-[400]  text-[16px] font-nunito w-full lg:w-[300px]">
                    {desc}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <button className="font-nunito text-white font-[700] text-[14px] bg-primary p-[10px] flex items-center justify-center mt-[20px] rounded-[12px] h-[48px] w-[149px]" onClick={joinBeta}>
            Get Started
            <BsArrowRightShort className="text-[25px]"/>
          </button>
        </div>
        <div>
          <img src={signal} alt="" className="hidden lg:block h-[968px]" />
          <img src={signalMobile} alt="" className=" lg:hidden" />
        </div>
      </div>
    </div>
  );
}

export default SupplyBusiness;
