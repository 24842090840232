import React from 'react'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import AboutHero from '../../components/AboutHero'
import OurStory from '../../components/OurStory'
import AboutWhatWeDo from '../../components/AboutWhatWeDo'
import Team from '../../components/Team'
import Interest from '../../components/Interest'


function AboutUs() {
  return (
    <div>
        <Navbar/>
        <AboutHero/>
        <OurStory/>
        <AboutWhatWeDo/>
        <Team/>
        <Interest/>
        <Footer/>
    </div>
  )
}

export default AboutUs