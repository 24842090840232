import React from 'react'
import screan from '../../assets/merchantHero.png';
import merchantImgMobile from "../../assets/groupMobile.png";

function Group() {
  return (
    <div className='p-[20px] lg:p-0 relative w-full flex items-center justify-center mt-[52px] lg:mt-[40px]'>
        <img src={screan} alt="" className='hidden lg:block' />
        <img src={merchantImgMobile} alt="" className='block lg:hidden' />
    </div>
  )
}

export default Group