import React from "react";
import heroImage from "../../assets/heroImage.png";
import mobileImage from "../../assets/mobileHero.png";

function HomeHero() {
  const handleScroll = () => {
    window.scrollTo({
      top: 900,
      behavior: "smooth",
    });
  };
  return (
    <div className="grid lg:grid-cols-2 gap-[30px]  lg:gap-[190px] p-[20px] lg:p-[40px_50px] w-full justify-between ">
      <div>
        <p className="text-[32px] text-center lg:text-start lg:leading-[50px] lg:text-[45px] xl:text-[70px] font-alegreya  font-[700] leading-[40px] xl:leading-[72px]">
          {" "}
          Securing Finance For Everyone
        </p>
        <p className="hidden lg:block bg-[#013CE4] h-[4px] w-[80px] rounded-[4px] mt-[24px]"></p>
        <p className="text-center lg:text-start mt-[30px] lg:leading-[34px] text-[14px] xl:leading-[34px] lg:text-[18px] xl:text-[24px] font-nunito font-[400]">
          We create a financial footprint using African MSMEs using supply chain transaction and data.
        </p>
        <div className="hidden lg:block mt-[40px] w-full">
          <button
            className="bg-primary lg:p-[12px_16px] rounded-[12px] p-[12px_5px] lg:w-[161px] text-[#FFFFFF] text-[14px] font-[700] font-nunito"
            onClick={handleScroll}
          >
            Explore solutions
          </button>
        </div>
      </div>
      <div className="flex items-center justify-center lg:justify-end">
        <div className="">
          <img src={heroImage} alt="" className="hidden lg:block" />
          <img src={mobileImage} alt="" className="lg:hidden" />
        </div>
      </div>
      <div className="lg:hidden mt-[40px]  flex items-center justify-center w-full">
        <button
          className="bg-primary lg:p-[12px_16px] rounded-[12px] p-[12px_15px] lg:w-[161px] text-[#FFFFFF] text-[14px] font-[700] font-nunito w-fit "
          onClick={handleScroll}
        >
          Explore solutions
        </button>
      </div>
    </div>
  );
}

export default HomeHero;
