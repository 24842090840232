import React from "react";
import wallet from "../../assets/wallet (2).png";
import tick from "../../assets/tick.png";
import chat from "../../assets/chat.png";
import carrier from "../../assets/carrier.png";
import bag from "../../assets/bag.png";
import { BsArrowRightShort } from "react-icons/bs";
import ModalService from "../Modal/ModalService";
import JoinBeta from "../JoinBeta";

function HowToUse() {
  const joinBeta = () => {
    ModalService.open(JoinBeta)
  }
  return (
    <div className="p-[20px] lg:p-[40px_90px]">
      <h1 className="font-[800] font-nunito text-[25px] lg:text-[52px] text-[#101213] ">
        How to Use Crosspoint
      </h1>
      <div className="mt-[20px] lg:mt-[40px] grid sm:grid-cols-2 xl:grid-cols-3 gap-[20px] lg:gap-[40px]">
        {howto.map(({ image, id, title, description }) => (
          <div className={`${id !== 6 ? 'rounded-3xl z-1 shadow-lg shadow-blue-200/50 border-[4px] border-[#F7F7F8] h-[200px]' : ''} w-full lg:w-[380px]   lg:px-[20px] lg:h-[400px]  bg-[#FFFFFF] items-center justify-center flex flex-col`} key={id}>
            {id === 6 ? null : (
              <img src={image} className="h-[56px] lg:h-[120px] w-[56px] lg:w-[120px]" alt="" />
            )}
            {id !== 6 && (
              <h1 className="font-[700] mt-[24px] text-[16px] lg:text-[30px] text-[#101213] font-alegreya">
              {title}
            </h1>
            )}
           {
              id !== 6 && (
                <p className="text-[#101213] font-nunito text-[12px] lg:text-[20px]  text-center p-[10px] mt-[12px] lg:mt-[24px]">
                {description}
              </p>
              )
           }
            {id === 6 && (
              <button className=" font-nunito text-white font-[700] text-[14px] bg-primary p-[12px_16px] flex items-center  rounded-[12px]" onClick={joinBeta}>
                Get Started
                <BsArrowRightShort className="text-[25px]" />
              </button>
            )}
          </div>
        ))}
        {/* <div className="w-full lg:w-[380px] h-[350px] lg:h-[400px] rounded-3xl z-1 shadow-lg shadow-blue-200/50 border-[4px] border-[#F7F7F8] bg-[#FFFFFF] items-center justify-center flex flex-col">
          <img src={tick} className="h-[120px] w-[120px]" alt="" />
          <h1 className="font-[700] mt-[24px] text-[30px] text-[#101213] font-alegreya">
            Verify Your Account
          </h1>
          <p className="text-[#101213] font-nunito text-[20px]  text-center p-[10px] mt-[12px] lg:mt-[24px]">
            Complete your verification in a few simple steps.
          </p>
        </div>
        <div className="w-full lg:w-[380px] h-[400px] rounded-3xl z-1 shadow-lg shadow-blue-200/50 border-[4px] border-[#F7F7F8] bg-[#FFFFFF] items-center justify-center flex flex-col">
          <img src={chat} className="h-[120px] w-[120px]" alt="" />
          <h1 className="font-[700] mt-[24px] text-[30px] text-[#101213] font-alegreya">
            Join a Circle
          </h1>
          <p className="text-[#101213] font-nunito text-[20px]  text-center p-[10px] mt-[24px]">
            Add all your products and join relevant circles.
          </p>
        </div>
        <div className="w-full lg:w-[380px] h-[400px] rounded-3xl z-1 shadow-lg shadow-blue-200/50 border-[4px] border-[#F7F7F8] bg-[#FFFFFF] items-center justify-center flex flex-col">
          <img src={carrier} className="h-[120px] w-[120px]" alt="" />
          <h1 className="font-[700] mt-[24px] text-[30px] text-[#101213] font-alegreya">
            Buy Supplies
          </h1>
          <p className="text-[#101213] font-nunito text-[20px]  text-center p-[10px] mt-[24px]">
            Purchase supplies for your business and access discounts.
          </p>
        </div>
        <div className="w-full lg:w-[380px] h-[400px] rounded-3xl z-1 shadow-lg shadow-blue-200/50 border-[4px] border-[#F7F7F8] bg-[#FFFFFF] items-center justify-center flex flex-col">
          <img src={bag} className="h-[120px] w-[120px]" alt="" />
          <h1 className="font-[700] mt-[24px] text-[30px] text-[#101213] font-alegreya">
            Build Your Nest
          </h1>
          <p className="text-[#101213] font-nunito text-[20px]  text-center p-[10px] mt-[24px]">
            Build a Nest and access financial services.
          </p>
        </div>
        <div className="w-full lg:w-[380px] h-[400px]    bg-[#FFFFFF] items-center justify-center flex flex-col">
          <h1 className="font-[700] mt-[24px] text-[30px] text-[#101213] font-alegreya">
            Get Started
          </h1>
          <p className="text-[#101213] font-nunito text-[20px]  text-center p-[10px] mt-[24px]">
            Create a Crosspoint Account to get started
          </p>
          
        </div> */}
      </div>
    </div>
  );
}

export default HowToUse;

const howto = [
  {
    id: 1,
    title: "Create Your Wallet",
    image: wallet,
    description: "Purchase supplies for your business and access discounts.",
  },
  {
    id: 2,
    title: "Verify Your Account",
    image: tick,
    description: "Complete your verification in a few simple steps.",
  },
  {
    id: 3,
    title: "Join a Circle",
    image: chat,
    description: "Add all your products and join relevant circles.",
  },
  {
    id: 4,
    title: "Buy Supplies",
    image: carrier,
    description: "Purchase supplies for your business and access discounts.",
  },
  {
    id: 5,
    title: "Build Your Nest",
    image: bag,
    description: "Build a Nest and access financial services.",
  },
  {
    id: 6,
    title: "Get Started",
    image: "",
    description: "Create a Crosspoint Account to get started",
  },
];
