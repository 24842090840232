import React from "react";
// import front from "../../assets/front.png";
// import back from "../../assets/back.png";
import agric from "../../assets/agric.png";
import office from "../../assets/office.png";
import retail from "../../assets/retail.png";
import building from "../../assets/building.png";
import energy from "../../assets/energy.png";
// import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
const Category = () => {
  return (
    <div className="p-[20px] lg:p-[40px_90px]">
      <div className="flex  justify-between items-center gap-[20px] mt-[20px]">
        <h1 className="text-[20px] lg:text-[56px] font-[800] text-[#101213] font-nunito leading-[80px]">
          Categories
        </h1>
        {/* <div className="hidden lg:flex space-x-[20px]">
          <div className=" bg-[#F7F7F8] h-fit rounded-full p-[10px] flex justify-center items-center">
            <BsArrowLeft className="text-[35px]" />
          </div>
          <div className=" bg-[#F7F7F8] h-fit rounded-full p-[10px] flex justify-center items-center">
            <BsArrowRight className="text-[35px]" />
          </div>
        </div> */}
      </div>
      <div className=" mt-[20px] lg:mt-[41px] grid sm:grid-cols-2 xl:grid-cols-3 gap-y-[12px] lg:gap-y-[40px]">
        {
          categoryItems.map(({id, title, img})=> (
            <div className="w-full lg:w-[400px] bg-[#FFFFFF] rounded-3xl z-1 shadow-lg shadow-blue-200/50 border-[#F7F7F8] border-[2px] flex-col justify-center items-center p-[40px] space-y-[12px] lg:space-y-[40px]" key={id}>
          <p className="font-[700] text-[20px] lg:text-[32px] text-[#101213] font-alegreya w-full text-center">
            {title}
          </p>
         <div className="flex items-center justify-center">
         <img className="h-[56px] lg:h-[160px]" src={img} alt="" />
         </div>
        </div>
          ))
        }
      </div>
    </div>
  );
};

export default Category;

const categoryItems = [
  {
    id: 1,
    title: "Food and Agriculture",
    img: agric,
  },

  {
    id: 2,
    title: "Office",
    img: office,
  },

  {
    id: 3,
    title: "Retail",
    img: retail,
  },

  {
    id: 4,
    title: "Building and Construction",
    img: building,
  },

  {
    id: 5,
    title: "Fuel and Energy",
    img: energy,
  },
];
