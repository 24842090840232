import React from "react";
import whiteLogo from "../../assets/whiteLogo.webp";
import {BsBriefcase} from 'react-icons/bs'
import {BiCopyright} from 'react-icons/bi'
import { CiLinkedin} from 'react-icons/ci'
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate()
  const ourStory = ()=>{
    navigate('/about-us');
    window.scrollTo({
      top:1000,
      behavior: 'smooth'
    })
  }
  const whatWeDo = ()=>{
    navigate('/about-us');
    window.scrollTo({
      top:2100,
      behavior: 'smooth'
    })
  }
  const team = ()=>{
    navigate('/about-us');
    window.scrollTo({
      top:2900,
      behavior: 'smooth'
    })
  }
    const footerLinks = [
        {
            id: 1,
            name:'Buyer',
            link: '/buyers'
        },
        {
            id: 2,
            name:'Supplier',
            link: '/merchants'
        },
        {
            id: 3,
            name:'Our Story',
            handleClick: ourStory
        },
        {
            id: 4,
            name:'What We Do',
            handleClick: whatWeDo
        },
        {
            id: 5,
            name:'Team',
            handleClick: team
        },
        {
            id: 6,
            name:'LinkedIn',
            link: '/'
        },
        
        {
            id: 7,
            name:'Mail',
            link: 'mobi@crosspoint.finance'
        },
    ]
  return (
    <div className="p-[40px_20px] lg:p-[70px_80px] bg-[#0413B7] mt-[80px]">
      <div className="grid  lg:grid-cols-2 space-y-[50px] lg:space-y-0">
      <div>
        <Link to='/'>
          {" "}
          <img src={whiteLogo} alt="" className="w-[230px] h-[49px]" />
        </Link>
        <p className="font-nunito font-[500] text-[16px] mt-[10px] text-[#FFFFFF]">
          We offer digital services that cater to a wide range <br className="hidden lg:block" /> of
          business concerns. Reach us on our socials.
        </p>
        <div className="mt-[20px] flex items-center space-x-[24px]">
           <a href="mailto:mobi@crosspoint.finance">
           <BsBriefcase className="text-[20px] text-[#FFFFFF] cursor-pointer"/>
           </a>
            <a href="https://www.linkedin.com/company/crosspoint-finance/" target="_blank" rel="noreferrer" >
            <CiLinkedin className="text-[25px] text-[#FFFFFF] cursor-pointer"/>
            </a>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 space-y-[30px] lg:space-y-0">
        <div>
            <p className="font-[700] font-nunito text-[16px] text-[#FFFFFF]">Products</p>
           <div className="mt-[10px] flex flex-col space-y-[5px]">
           {footerLinks.slice(0,2)
          .map(({id, name, link, handleClick})=> (
            <Link className="font-[500] text-[#FFFFFF] text-[16px] font-nunito" to={link} key={id} >{name}</Link>
          ))}
           </div>
        </div>
        <div>
        <div>
            <p className="font-[700] font-nunito text-[16px] text-[#FFFFFF]">About Us</p>
           <div className="mt-[10px] flex flex-col items-start space-y-[5px]">
           {footerLinks.slice(2,5)
          .map(({id, name, link, handleClick})=> (
            <button className="font-[500] text-[#FFFFFF] text-[16px] font-nunito" key={id}  onClick={handleClick}>{name}</button>
          ))}
           </div>
        </div>
        </div>
        <div>
        <div>
            <p className="font-[700] font-nunito text-[16px] text-[#FFFFFF]">Socials</p>
           <div className="mt-[10px] flex flex-col space-y-[5px]">
           <div>
              <a href="https://www.linkedin.com/company/crosspoint-finance/" target="_blank" rel="noreferrer" className="font-[500] text-[#FFFFFF] text-[16px] font-nunito">LinkedIn</a>
            </div>
            <div>
              <a href="mailto:mobi@crosspoint.finance" className="font-[500] text-[#FFFFFF] text-[16px] font-nunito">Mail</a>
            </div>
            
           {/* {footerLinks.slice(5, footerLinks.length)  
          .map(({id, name, link})=> (
            <a  to={link} key={id}>{name}</a>
          ))} */}
           </div>
        </div>
        </div>
      </div>
      </div>
      <div className="w-full items-center justify-start mt-[70px]">
        <p className='flex items-center font-nunito text-[#FFFFFF]'>Copyright <BiCopyright className="mx-[5px] text-[20px]"/> Crosspoint 2023. <span className="hidden lg:block">All rights reserved</span></p>
      </div>
    </div>
  );
}

export default Footer;
