import React, {useState, useEffect} from "react";
import aboutHero from "../../assets/aboutHero.webp";
import './style.css'
import network from '../../assets/network.png'

function AboutHero() {
  const heroTexts = [
    "Commerce",
    "Industry",
    "Families",
    "Community",
    "Finance",
  ];
  const [index, setIndex] = useState(0);


  useEffect(()=> {
    const intervalId = setInterval(()=> {
      setIndex((index + 1)% heroTexts.length);
    }, 4000);
    return () => clearInterval(intervalId);
  }, [index, heroTexts.length])

  // 
  return (
    <div className="p-[20px] lg:p-[24px] flex flex-col items-center justify-center space-y-[40px]">
      <div className="flex flex-col lg:items-center justify-center">
        <div className="flex items-center justify-center gap-4">
        <img className="h-[45px] w-[45px]" src={network} alt=""/>
        <p className="font-alegreya font-[700] text-[32px] lg:text-[72px] leading-[36px] lg:leading-[72px] lg:flex items-center ">
          Powering
          <span className="h-[36px] lg:max-h-[72px] lg:min-h-[72px] px-[10px]">
           
           <span className="movement font-alegreya font-[700] text-[32px] lg:text-[72px] text-primary lg:mx-[10px] leading-[36px] lg:leading-[72px] flex items-center justify-center text-center ">
           {heroTexts[index]}
           </span>
          </span>
          is our Goal
        </p>
        </div>
        <p className="font-[400] font-nunito text-[16px] text-start lg:text-center mt-[32px] lg:mt-[40px]">
          At Crosspoint, we are powering the future of commerce through the the
          power of <br className="hidden lg:block" /> community and providing
          financial inclusion for businesses
        </p>
      </div>
      <div className="">
        <img src={aboutHero} alt="" className="" />
      </div>
    </div>
  );
}

export default AboutHero;
