import React from 'react'
import {BiChevronRight} from 'react-icons/bi'
import JoinBeta from '../JoinBeta'
import ModalService from '../Modal/ModalService'

function MerchantHero() {
  const joinBeta = () => {
    ModalService.open(JoinBeta)
  }
  return (
    <div className='p-[20px] lg:p-0 w-full flex flex-col items-start lg:items-center justify-center'>
        <p className='font-[700] font-alegreya text-[32px] lg:text-[72px] leading-[36px] lg:leading-[72px] text-start lg:text-center'>Sell smarter, <br className='block lg:hidden' /> fast and easy</p>
        <p className='font-[400] text-start lg:text-center font-nunito text-[16px] leading-[24px] mt-[24px] lg:mt-[40px] '>Join Crosspoint's network of suppliers, sell to buying <br  className='hidden lg:block'/> communities, process orders conveniently , track your
        <br  className='hidden lg:block'/> sales, earn rewards and do much more.</p>
        <button className='bg-primary text-white font-[700] font-nunito p-[12px_16px] flex items-center mt-[36px] lg:mt-[40px] space-x-[12px]  rounded-[12px] ' onClick={joinBeta}>
          <span className='pl-[8px]'>Get started</span>
                  <BiChevronRight className='text-[24px] '/>
        </button>
    </div>
  )
}

export default MerchantHero