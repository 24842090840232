import React from 'react'
import Contact from '../../components/Contact'
import Navbar from '../../components/Navbar'

function ContactUs() {
  return (
    <div>
        <Navbar/>
        <Contact/>
    </div>
  )
}

export default ContactUs