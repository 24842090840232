import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function Newsletter() {
  const [email, setEmail] = useState("");
  const joinMailList = async (e) => {
    e.preventDefault();
    if (!email.trim()) {
      toast.warn("Please enter a valid email address");
    } else {
      axios
        .post(process.env.REACT_APP_API_URL + "/waitList/add", { email })
        .then((res) => {
          if (res.status === 200) {
            toast.success("You have successfully joined our mailing list");
            setEmail("");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };
  return (
    <div className="p-[20px] lg:p-[50px] flex flex-col items-center justify-center w-full space-y-[24px] lg:space-y-[45px] mb-[80px]">
      <p className="font-[700] font-alegreya text-[24px] lg:text-[48px] leading-[36px] lg:leading-[52px]  text-center">
        Join Waitlist
      </p>
      <p className="text-center text-[16px] font-nunito font-[400]">
      Be the first to know when we launch and use <br className="hidden lg:block" /> more tools designed for business growth.
        
      </p>
      <form
        action=""
        className="w-full flex flex-col lg:flex-row space-y-[20px] lg:space-x-[20px]  lg:space-y-0 items-center justify-center"
        onSubmit={joinMailList}
        
      >

        <input
          type="email"
          placeholder="Enter your email "
          className="w-full lg:w-[400px] h-[48px] border border-[#E5E5E5] placeholder:font-nunito font-nunito outline-none rounded-[8px] px-[16px] text-[16px] font-[400] "
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          type="submit"
          className="bg-primary h-[48px] w-full  lg:w-[150px] text-center flex items-center justify-center text-[#FFFFFF] font-nunito rounded-[8px] font-[600] "
        >
          Join Waitlist
        </button>
      </form>
    </div>
  );
}

export default Newsletter;
