import React from 'react'
import Modal from '../Modal'
import tick from '../../assets/tick.png'
function Done(props) {
  return (
    <Modal>
       <div className='w-ful flex flex-col justify-center items-center'>
       <p className="text-[#101213] font-[700] text-[24px] font-alegreya">
          Done !
        </p>
        <div className=''>
            <img src={tick} alt="" />
        </div>
        <p className='font-[600] text-center font-nunito text-[16px] leading-[24px]'>A member of our team would contact you soon <br className='hidden lg:block' /> to set up a demo</p>
        <div className="flex items-center justify-center w-full mt-[24px]">
            <button
              type="button"
              className="bg-[#013CE4] text-white font-nunito font-[700] p-[12px_20px] rounded-[12px]"
              onClick={props.close}
            >
              Great
            </button>
          </div>
       </div>
    </Modal>
  )
}

export default Done