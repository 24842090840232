import React from 'react'

function Modal(props) {
  return (
    <div className="transition-all fixed top-0 z-40 w-full mx-auto bg-[#2F3546CC] h-full lg:h-screen flex items-center justify-center p-[10px] md:p-0">
      <div className=" w-full lg:w-[50%]  bg-[#FFFFFF] rounded-[10px] p-[15px] md:p-[24px_35px] ">
        {props.children}
      </div>
    </div>
  )
}

export default Modal