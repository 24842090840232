import React, { useState, useEffect } from "react";
import buyerHero from "../../assets/buyerHero.svg";
import { BsArrowRightShort } from "react-icons/bs";
import ModalService from "../Modal/ModalService";
import JoinBeta from "../JoinBeta";

function BuyerHero() {
  const heroTexts = [
    "buy",
    "plan expenses",
    "do business",
    "save money",
    "grow",
  ];
  const [index, setIndex] = useState(0);

  const joinBeta = () => {
    ModalService.open(JoinBeta)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((index + 1) % heroTexts.length);
    }, 4000);
    return () => clearInterval(intervalId);
  }, [index, heroTexts.length]);

  return (
    <div className="p-[20px] lg:p-[40px]">
    <div className="grid lg:grid-cols-2  lg:p-[40px_50px]">
      <div className="flex flex-col items-start justify-center w-full">
        <p className="font-[700] font-nunito w-full text-center lg:text-start  text-[14px]  text-[#013CE4]  lg:text-[20px]">
          SAVE WHILE YOU BUY EVERYTHING
        </p>
        <div className="mt-[16px] flex flex-col items-center lg:items-start w-full justify-center">
        <p className="font-alegreya font-[700] text-xl lg:text-[72px] text-[#000000] m-0 leading-[36px] lg:leading-[72px]">
          A better way to
        </p>
        <div className="h-[36px] lg:max-h-[72px] lg:min-h-[72px] ">
          <span className="movement font-alegreya font-[700] text-[32px] lg:text-[72px] text-primary  leading-[36px] lg:leading-[72px] flex items-center justify-center text-center ">
            {heroTexts[index]}
          </span>
        </div>
        </div>
        {/* <p className='font-alegreya font-[700] text-[32px] lg:text-[72px] text-[#F7F7F8] m-0 leading-[36px] lg:leading-[72px]'>buy</p> */}
        <div className="hidden lg:block">
          <p className="font-nunito text-[16px] font-[400] leading-[24px]  mt-[24px]">
            Crosspoint is the social commerce and{" "}
            <br className="hidden lg:block" /> finance partner for the communities across Africa.
             {/* <br className="hidden lg:block" /> Make group procurements, track inventory and
             <br/>cut expenses on Crosspoint */}
          </p>
          <button className="font-nunito text-white font-[700] text-[14px] bg-primary p-[12px_16px] flex items-center mt-[20px] rounded-[12px]" onClick={joinBeta}>
            Join Beta
            <BsArrowRightShort className="text-[25px]" />
          </button>
        </div>
      </div>
      <div className="mt-[40px] lg:mt-0">
        <img src={buyerHero} alt="" />
      </div>
      <div className="flex flex-col items-center lg:hidden">
          <p className="font-nunito text-[14px] text-center lg:text-[16px] font-[400] leading-[24px]  mt-[24px]">
            Crosspoint is the social procurement and{" "}
            <br className="hidden lg:block" /> finance partner for the communities across Africa.
             <br className="hidden lg:block" /> Make group procurements, track inventory and
             <br className="hidden lg:block"/>cut expenses on Crosspoint
          </p>
          <button className="font-nunito text-white font-[700] text-[14px] bg-primary p-[12px_20px] flex items-center mt-[20px] rounded-[12px]">
            Join Beta
            <BsArrowRightShort className="text-[25px]" />
          </button>
        </div>
    </div>
    <p className='font-[700] text-[20px] leading-[24px] lg:text-[50px] text-[#101213] justify-center text-center font-nunito mt-[70px]'>Supersize your buying power <span className='text-[#013CE4]'>now</span></p>
    </div>
  );
}

export default BuyerHero;
