import React, { useState } from "react";
import { toast } from "react-toastify";
import ModalService from "../Modal/ModalService";
import BetaCongrats from "../BetaCongrats";
import Modal from "../Modal";
import axios from "axios";
import { FaTimes } from "react-icons/fa";

function JoinBeta(props) {
  const [demoData, setDemoData] = useState({
    name: "",
    businessName: "",
    email: "",
  });
  const { name, email, businessName } = demoData;
  const requestDemo = async (props) => {
    if (!name.trim() || !email.trim() || !businessName.trim()) {
      toast.warn("Please fill all Fields");
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_WEBAPI_URL + "/website/beta",
          demoData
        );
        if (response?.data.status === "SUCCESS") {
          props.close();
          ModalService.open(BetaCongrats);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    }
  };
  return (
    <Modal>
      <div>
        <div className="w-full flex items-start justify-between">
          <div>
            <p className="text-[#101213] font-[700] text-[24px] font-alegreya">
              Join Crosspoint Beta
            </p>
            <p className="mt-[24px] font-nunito font-[600] text-[16px] text-[#1F232E]">
              Enter your details to get notified when Crosspoint launches
            </p>
          </div>
          <div className="cursor-pointer" onClick={props.close}>
            <FaTimes className="text-[25px]" />
          </div>
        </div>

        <form action="" className="mt-[24px] space-y-[24px]">
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-[#101213] font-[400] font-nunito text-[16px] "
            >
              Tell us your name
            </label>
            <input
              type="text"
              className="mt-[16px] border border-[#E3E5E8] h-[50px] rounded-[12px] placeholder:font-nunito px-[10px] outline-none"
              placeholder="John Doe"
              onChange={(e) =>
                setDemoData({ ...demoData, name: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-[#101213] font-[400] font-nunito text-[16px] "
            >
              Tell us your Business name
            </label>
            <input
              type="text"
              className="mt-[16px] border border-[#E3E5E8] h-[50px] rounded-[12px] placeholder:font-nunito px-[10px] outline-none"
              placeholder="CrossPoint Ventures"
              onChange={(e) =>
                setDemoData({ ...demoData, businessName: e.target.value })
              }
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-[#101213] font-[400] font-nunito text-[16px] "
            >
              Tell us your Email
            </label>
            <input
              type="email"
              placeholder="johndoe@gmail.com"
              required
              className="mt-[16px] border border-[#E3E5E8] h-[50px] rounded-[12px] placeholder:font-nunito px-[10px] outline-none"
              onChange={(e) =>
                setDemoData({ ...demoData, email: e.target.value })
              }
            />
          </div>
          <div className="flex items-center justify-end w-full">
            <button
              type="button"
              className="bg-[#013CE4] text-white font-nunito font-[700] p-[12px_16px] rounded-[12px]"
              onClick={() => requestDemo(props)}
            >
              Join Beta
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default JoinBeta;
