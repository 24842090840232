import React from 'react'
import { AiOutlineCheck } from "react-icons/ai";
import Building from "../../assets/building.webp";
import {FaQuoteLeft} from 'react-icons/fa'
import JoinBeta from '../JoinBeta';
import ModalService from '../Modal/ModalService';



function AboutWhatWeDo() {
  const joinBeta = () => {
    ModalService.open(JoinBeta)
  }
  return (
    <div className='p-[20px] lg:p-[50px]'>
      <div></div>
        <p className='font-[700] text-[24px] lg:text-[48px] font-alegreya leading-[52px] text-[#101213]'>What We Do</p>
        <div className='mt-[24px] lg:mt-[40px] grid lg:grid-cols-2'>
            <div >
                <p className='bg-primary w-[80px] h-[4px] rounded-[4px]'></p>
                <p className='mt-[40px] font-nunito text-[16px]'>We help medium and small businesses build a financial footprint from their supply chain transactions and create pathways for them to deepen access to financial services</p>
                <div className="mt-[40px] glex flex-col space-y-[24px]">
                <div className="flex items-center space-x-[20px]">
                  <AiOutlineCheck />
                  <p className="font-nunito text-[16px] font-[400] text-[#141F1B]">
                  Finance
                  </p>
                </div>
                <div className="flex items-center space-x-[20px]">
                  <AiOutlineCheck />
                  <p className="font-nunito text-[16px] font-[400] text-[#141F1B]">
                  Inventory And Supply Chain
                  </p>
                </div>
                <div className="flex items-center space-x-[20px]">
                  <AiOutlineCheck />
                  <p className="font-nunito text-[16px] font-[400] text-[#141F1B]">
                  Intelligence And Data
                  </p>
                </div>
                <div className="flex items-center space-x-[20px]">
                  <AiOutlineCheck />
                  <p className="font-nunito text-[16px] font-[400] text-[#141F1B]">
                 E-procurement
                  </p>
                </div>
                
                {/* <div className="flex items-center space-x-[20px]">
                  <AiOutlineCheck />
                  <p className="font-nunito text-[16px] font-[400] text-[#141F1B]">
                  Cybersecurity
                  </p>
                </div>
                <div className="flex items-center space-x-[20px]">
                  <AiOutlineCheck />
                  <p className="font-nunito text-[16px] font-[400] text-[#141F1B]">
                  E-commerce
                  </p>
                </div>
                <div className="flex items-center space-x-[20px]">
                  <AiOutlineCheck />
                  <p className="font-nunito text-[16px] font-[400] text-[#141F1B]">
                  Inventory and Supply chain
                  </p>
                </div> */}
              </div>
              <button className='bg-primary font-nunito text-white font-[700] text-[14px] mt-[40px] p-[12px_16px] rounded-[8px] ' onClick={joinBeta}>Get started</button>
              
            </div>
            <div className='flex flex-col items-center justify-end mt-[80px] lg:mt-0'>
                <img src={Building} alt="" className="w-[335px] lg:w-[514px] h-[500px] object-cover" />
                <div className="mt-[60px] flex flex-col items-center">
            <FaQuoteLeft className="text-[25px]"/>
            <p className="font-nunito text-[16px] leading-[24px] font-[400] text-center mt-[24px] " >We are in the business of stronger commerce, stronger finance,<br  className='hidden lg:block'/> and stronger communities</p>
         </div>
            </div>
        </div>
    </div>
  )
}

export default AboutWhatWeDo