import React from 'react'
import Modal from '../Modal'
import congrats from '../../assets/congrats.png'
function BetaCongrats(props) {
  return (
    <Modal>
       <div className='w-ful flex flex-col justify-center items-center'>
       <p className="text-[#101213] font-[700] text-[24px] font-alegreya">
          Congratulations !
        </p>
        <div className='mt-[20px]'>
            <img src={congrats} alt="" className='h-[180px] lg:h-[250px] w-[180px] lg:w-[250px]' />
        </div>
        <p className='font-[600] text-center font-nunito text-[16px] leading-[24px]'>Managing your procurement is about to get easier. You would be notified as soon as Crosspoint launches.</p>
        <div className="flex items-center justify-center w-full mt-[24px]">
            <button
              type="button"
              className="bg-[#013CE4] text-white font-nunito font-[700] p-[12px_20px] rounded-[12px]"
              onClick={props.close}
            >
              Great!
            </button>
          </div>
       </div>
    </Modal>
  )
}

export default BetaCongrats