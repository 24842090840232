import React from 'react'
import Footer from '../../components/Footer'
import HomeHero from '../../components/HomeHero'
import Navbar from '../../components/Navbar'
import Newsletter from '../../components/Newsletter'
import AllInOne from '../../components/AllInOne'
import SupplyBusiness from '../../components/SupplyBusiness'

function Home() {
  return (
    <div>
       <Navbar/>
       <div className='flex flex-col space-y-[32px] lg:space-y-[80px]'>
       <HomeHero/>
       <AllInOne/>
       <SupplyBusiness/>
       <Newsletter/>
       </div>
       <Footer/>
    </div>
  )
}

export default Home