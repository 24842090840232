import React from "react";
import mockup from "../../assets/allinone.png";
import People from "../../assets/people.png";
import Access from "../../assets/parcel.png";
import { Link } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";

function AllInOne() {
  return (
    <div className=" bg-[#F7F7F8] flex flex-col items-center py-[20px]
     justify-center space-y-[30px]">
      <div className="  grid grid-row-2 gap-[12px] lg:gap-[80px] p-[20px] lg:p-[40px]">
        <div>
          <div className="bg-[#FFFFFF] flex flex-col-reverse lg:grid lg:grid-cols-2   mt-[10px] lg:mt-[48px] z-1 shadow-lg shadow-blue-200/50 p-[20px] lg:p-[40px_60px] rounded-3xl">
            <div className="flex flex-col items-start justify-start w-full">
              <h1 className="font-[700] text-[#101213] text-[20px] lg:text-[48px] leading-[24px] lg:leading-[56px] mt-0 lg:mt-[30px] font-nunito">
                An all-in-one <br className="hidden lg:block" /> supply chain and {" "}
                <br className="hidden lg:block" /> finance tracking platform
              </h1>
              <p className="font-nunito text-[14px] lg:text-[24px] text-[#101213] mt-[20px] lg:mt-[40px] ">
                Optimize supply chain spending,{" "}
                <br className="hidden lg:block" /> 
                cut time spent on avoidable tasks<br className="hidden lg:block" />
                and reduce unecessary paperwork.
              </p>
            </div>
            <div className="">
              <img src={mockup} alt="" />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-[12px] lg:gap-[80px] w-full ">
          <div className="bg-[#FFFFFF] p-4 lg:p-8 rounded-2xl z-1 shadow-lg shadow-blue-200/50">
            <div className="bg-[#EBF0FF] p-4">
              <img src={People} alt="" className="w-full h-32 lg:h-64" />
            </div>
            <div className="flex flex-col justify-center mt-4 lg:mt-0">
              <p className="font-bold text-lg lg:text-2xl leading-6 lg:leading-8 text-[#101213] mt-4 font-nunito">
                Save time and money when you buy with Crosspoint
              </p>
              <p className="text-base lg:text-lg font-nunito text-[#101213] mt-2 lg:mt-4">
               Be guaranteed of the best value on supplies
              </p>
            </div>
          </div>
          <div className="bg-[#FFFFFF] p-4 lg:p-8 rounded-2xl z-1 shadow-lg shadow-blue-200/50">
            <div className="bg-[#EBF0FF] p-4">
              <img src={Access} alt="" className="w-full h-32 lg:h-64" />
            </div>
            <div className="flex flex-col justify-center mt-4 lg:mt-0">
              <p className="font-bold text-lg lg:text-2xl leading-6 lg:leading-8 text-[#101213] mt-4 font-nunito">
                Get Access to a wide range of supplies
              </p>
              <p className="text-base lg:text-lg font-nunito text-[#101213] mt-2 lg:mt-4">
                We cater to a wide range of supplies. From
                agricultural supplies to office supplies, we have you covered.
              </p>
            </div>
          </div>
        </div>
      </div>
      <button className="bg-primary flex items-center justify-center space-x-[10px] lg:p-[12px_16px] rounded-[12px] p-[15px_20px] lg:w-[161px] text-[#FFFFFF] text-[14px] font-[700] font-nunito w-fit mt-[60px]">
          <Link to="/buyers">Learn More</Link>{" "}
          <BsArrowRightShort className="text-[25px]" />
        </button>
    </div>
  );
}

export default AllInOne;

