import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Buyers from "./pages/Buyers";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import Merchant from "./pages/Merchants";
import AboutUs from "./pages/AboutUs";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalRoot from "./components/Modal/ModalRoot";
function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/buyers' element={<Buyers/>}/>
        <Route path='/merchants' element={<Merchant/>}/>
        <Route path='/contact-us' element={<ContactUs/>} />
        <Route path='/about-us' element={<AboutUs/>} />
       </Routes>
    </Router>
    <ModalRoot/>
    <ToastContainer />

    </>
  );
}

export default App;
