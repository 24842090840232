import React from "react";
import Footer from "../../components/Footer";
import BuyerHero from "../../components/BuyerHero";
import Navbar from "../../components/Navbar";
import Procurement from "../../components/Procurement";
import HowTo from "../../components/HowTo";
import Category from "../../components/Categories";
import WaitList from "../../components/WaitList";
import HowToUse from "../../components/HowToUse";

function Buyers() {
  return (
    <div>
      <Navbar />
      <BuyerHero />
      <HowTo />
      <HowToUse/>
      <Procurement />
      <Category />
      <WaitList />
      <Footer />
    </div>
  );
}

export default Buyers;
