import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.webp";
import menuIcon from "../../assets/menu.webp";
import mobileLogo from "../../assets/mobileLogo.webp";
import ModalService from '../Modal/ModalService'
import RequestDemo from "../RequestDemo";
import JoinBeta from "../JoinBeta";
function Navbar() {
  const [open, setOpen] = React.useState(false);
  const navLinks = [
    {
      id: 2,
      name: "Buyer",
      link: "/buyers",
    },
    {
      id: 3,
      name: "Supplier",
      link: "/merchants",
    },
    {
      id: 4,
      name: "About Us",
      link: "/about-us",
    },
    {
      id: 5,
      name: "Contact Us",
      link: "/contact-us",
    },
  ];

  const requestDemo = () => {
    setOpen(false)
    ModalService.open(RequestDemo)
  }
  const joinBeta = () => {
    setOpen(false)
    ModalService.open(JoinBeta)
  }
  return (
    <div className=" lg:p-[30px_50px] flex items-center justify-between ">
      <div className="p-[20px_15px] lg:p-0 w-full lg:w-fit flex items-center justify-between lg:justify-start">
        <div>
          <Link to="/">
            {" "}
            <img
              src={mobileLogo}
              alt=""
              className="block lg:hidden w-[90%] min-h-full cursor-pointer"
            />
            <img
              src={logo}
              alt=""
              className="hidden lg:block min-w-full max-w-full min-h-full"
            />
          </Link>
        </div>
        <div className="block lg:hidden" onClick={()=> setOpen(!open)}>
            <img src={menuIcon} alt="" className="w-[36px] h-[36px]"/>
            {/* <FaBars className="text-[25px] cursor-pointer"/> */}
        </div>
      </div>


      <div className="hidden lg:flex items-center space-x-[40px] ">
        {navLinks.map(({ id, name, link }) => (
          <Link
            className="font-nunito font-[500] text-[14px] text-[#101213]"
            to={link}
            key={id}
          >
            {name}
          </Link>
        ))}
      </div>
      <div className="hidden lg:flex items-center space-x-[15px]">
        <button className="text-[14px] rounded-[12px] bg-primary font-[700] text-white p-[12px_16px] font-nunito" onClick={joinBeta}>
          Join Beta
        </button>
        <button className="text-[14px] rounded-[12px] bg-white font-[700] font-nunito text-[#101213] p-[12px_16px]" onClick={requestDemo}>
          Request Demo
        </button>
      </div>




      <div className={`${open ? 'left-0' : 'left-[-100%]'} top-0 fixed transition-all bg-white  z-30 h-screen lg:hidden w-[70%] p-[20px]`}>
      <div className="flex flex-col items-center space-y-[40px] w-full">
        {navLinks.map(({ id, name, link }) => (
          <Link
            className="w-full font-nunito text-[14px] text-[#101213]"
            to={link}
            key={id}
          >
            {name}
          </Link>
        ))}
      </div>
      <div className="flex flex-col items-center lg:space-x-[15px] w-full mt-[20px]">
        <button className="rounded-[12px] bg-primary text-white p-[12px_16px] font-nunito w-full" onClick={joinBeta}>
        Join Beta
        </button>
        <button className="mt-[20px] lg:m-0 border border-primary lg:border-none rounded-[12px] bg-white text-[#101213] p-[12px_16px] w-full" onClick={requestDemo}>
        Request Demo
        </button>
      </div>
      </div>
    </div>
  );
}

export default Navbar;
