import React from "react";
import Dashboard from "../../components/Dashboard";
import Footer from "../../components/Footer";
import Group from "../../components/Group";
import MerchantHero from "../../components/MerchantHero";
import Navbar from "../../components/Navbar";
import Newsletter from "../../components/Newsletter";

function Merchant() {
  return (
    <div>
      <Navbar />
      <div className=" lg:p-[40px_50px] ">
        <MerchantHero />
        <Group />
        <Dashboard />
      </div>
      <Newsletter/>
      <Footer />
    </div>
  );
}

export default Merchant;
