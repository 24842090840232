import React from "react";
import {FaQuoteLeft} from 'react-icons/fa'
import compass from "../../assets/compass.png";
import sky from '../../assets/sky.png'

function OurStory() {
  return (
    <div className="p-[20px] lg:p-[50px] mt-[80px]">
      <p className="font-[700] text-[24px] lg:text-[48px] leading-[32px] lg:leading-[52px] font-alegreya">
        Our Story
      </p>
      <div className="grid lg:grid-cols-2 gap-x-[120px] mt-[24px] lg:mt-[40px]">
        <div>
          <p className="w-[80px] h-[4px] bg-primary"></p>
          <p className="font-nunito text-[16px] lg:text-[18px] leading-[24px] font-[400] text-[#000000] mt-[24px]  lg:mt-[64px]">
          Financial exclusion is a problem that has plagued low-income individuals 
          and businesses in Nigeria and across the world. But solving this problem
           goes beyond making financial services cheaper or easy to discover alone. 
          </p>
          <p className="mt-[20px] font-nunito text-[16px] lg:text-[18px] leading-[24px] font-[400] text-[#000000]">
          Our model of social finance helps 
          communities access finance by making their supply chain spending systemized, 
          simple and traceable. We make it easier to identify leakages, provide credit, 
          reduce cost and embed finance into their supply chain spending experience. 
          We are on a mission to create an integrated supply chain and finance ecosystem 
          for Africa and the rest of the developing world.
          </p>
          <div className="mt-[64px] w-full flex items-center justify-center">
            <img src={compass} alt="" className="w-[335px] lg:w-[600px] h-[500px] object-cover" />
          </div>
        </div>
        <div className=" flex flex-col-reverse lg:flex-col items-center justify-start">
         <div className="mt-[72px] lg:mt-0">
            <img src={sky} alt="" className="w-[335px] lg:w-[514px] h-[500px] object-cover"  />
         </div>
         <div className="mt-[60px] flex flex-col items-center">
            <FaQuoteLeft className="text-[25px]"/>
            <p className="font-nunito text-[16px] leading-[24px] font-[400] text-center mt-[24px]" >Making Finance inclusive</p>
         </div>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
