import React from 'react'

const WaitList = () => {
  return (
    <div className=' flex justify-center items-center flex-col mt-[100px] w-full px-[15px]'>
        <h1 className='font-bold text-[24px] lg:text-[50px] text-[#101213] font-alegreya'>Join Waitlist</h1>
        <p className='text-[14px] lg:text-[20px] font-normal text-[#101213] mt-[24px] lg:mt-[40px] text-center'>Be the first to know when we launch and get access to
            <br className='hidden lg:block'/>tools designed for business growth.
        </p>
        <div className='flex flex-col lg:flex-row gap-4 mt-[30px] w-full lg:w-fit '>
        <input className='w-full lg:w-[508px] h-[50px] border-[#E3E5E8] border-2 rounded-lg p-[12px] outline-none' placeholder='Enter your email'/>
        <button className='bg-[#013CE4] text-[#FFFFFF] font-nunito font-[700] text-[14px] p-[15px_25px] rounded-[12px]'>Join Beta</button>
        </div>
    </div>
  )
}

export default WaitList