import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import mockup from "../../assets/Mockups.png";
import Grassroots from "../../assets/grassroots.jpg";
import Collaboration from "../../assets/collaboration.jpg";
import JoinBeta from "../JoinBeta";
import ModalService from "../Modal/ModalService";

function HowTo() {
  const joinBeta = () => {
    ModalService.open(JoinBeta)
  }
  return (
    <div
      className=" bg-[#F7F7F8] flex flex-col items-center py-[20px]
     justify-center space-y-[30px]"
    >
      <div className="  grid grid-row-2 gap-[12px] lg:gap-[80px] p-[20px] lg:p-[40px]">
        <div>
          <div className="bg-[#FFFFFF] flex flex-col-reverse lg:grid lg:grid-cols-2   mt-[10px] lg:mt-[48px] z-1 shadow-lg shadow-blue-200/50 p-[20px] lg:p-[40px_60px] rounded-3xl">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="font-bold text-[#013CE4] text-[10px] lg:text-[20px] font-nunito">
                WELCOME TO THE FUTURE OF COMMERCE
              </p>
              <h1 className="font-[700] text-[#101213] text-[20px] lg:text-[48px] leading-[24px] lg:leading-[56px] mt-[30px] font-nunito">
                Why{" "}
                <span className="text-[#013CE4] mx-[5px]">
                  buy alone
                </span>
                <br className="hidden lg:block" />
                when you can{" "}
                <span className="text-[#013CE4] mx-[5px]">buy</span>
                <br className="hidden lg:block" />
                <span className="text-[#013CE4]">better</span> with Crosspoint?
              </h1>
              <p className="font-nunito text-[14px] lg:text-[24px] text-[#101213] mt-[20px] ">
                Join our community today to align your supply
                <br className="hidden lg:block" />
                chain with your financial goals
              </p>
              <button className="font-nunito text-white font-[700] text-[14px] bg-primary p-[12px_16px] flex items-center mt-[20px] rounded-[12px]" onClick={joinBeta}>
                Get Started
                <BsArrowRightShort className="text-[25px]" />{" "}
              </button>
            </div>
            <div className="">
              <img src={mockup} alt="" />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-[12px] lg:gap-[80px] w-full ">
          <div className="bg-[#FFFFFF] p-4 lg:p-8 rounded-2xl z-1 shadow-lg shadow-blue-200/50">
            <div className="bg-[#EBF0FF] p-4">
              <img src={Grassroots } alt="" className="w-full h-32 lg:h-64" />
            </div>
            <div className="flex flex-col justify-center mt-4 lg:mt-0">
              <p className="font-bold text-lg lg:text-2xl leading-6 lg:leading-8 text-[#101213] mt-4 font-nunito">
                Working from the grass roots
              </p>
              <p className="text-base lg:text-lg font-nunito text-[#101213] mt-2 lg:mt-4">
                Supply chain and finance programs even for the most vulnerable communities
              </p>
            </div>
          </div>
          <div className="bg-[#FFFFFF] p-4 lg:p-8 rounded-2xl z-1 shadow-lg shadow-blue-200/50">
            <div className="bg-[#EBF0FF] p-4">
              <img src={Collaboration} alt="" className="w-full h-32 lg:h-64 filter brightness-[60px] " />
            </div>
            <div className="flex flex-col justify-center mt-4 lg:mt-0">
              <p className="font-bold text-lg lg:text-2xl leading-6 lg:leading-8 text-[#101213] mt-4 font-nunito">
                Promoting Collaboration
              </p>
              <p className="text-base lg:text-lg font-nunito text-[#101213] mt-2 lg:mt-4">
                We are facilitating collaboration between multiple stakeholders to improve outcomes
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowTo;

//     <div className=' px-[20px] lg:p-[40px_50px] bg-[#F7F7F8] flex flex-col lg:flex-row items-center justify-center w-full'>
//     <div className='bg-[#FFFFFF] grid lg:grid-cols-2 w-full  mt-[20px] lg:mt-[48px] z-1 shadow-lg shadow-blue-200/50 p-[20px] lg:p-[40px_60px] rounded-3xl'>
//         <div className='flex flex-col items-start justify-center w-full'>
//             <p className='font-bold text-[#013CE4] text-[10px] lg:text-[20px] font-nunito'>WELCOME TO THE FUTURE OF COMMERCE</p>
//             <h1 className='font-[700] text-[#101213] text-[20px] lg:text-[48px] leading-[24px] lg:leading-[56px] mt-[30px] font-nunito'>Why <span className='text-[#013CE4] mx-[5px]'>buy little alone</span>
//                 <br className='hidden lg:block'/>when you can <span className='text-[#013CE4] mx-[5px]'>buy</span>
//                 <br className='hidden lg:block'/><span className='text-[#013CE4]'>more</span> together?
//             </h1>
//             <p className='font-nunito text-[14px] lg:text-[24px] text-[#101213] mt-[20px] '>Join a Circle today and start buying all your
//                 <br className='hidden lg:block'/>business supplies in groups.
//             </p>
//             <button className="font-nunito text-white font-[700] text-[14px] bg-primary p-[12px_16px] flex items-center mt-[20px] rounded-[12px]">
//         Get Started
//         <BsArrowRightShort className="text-[25px]" />
//       </button>
//         </div>
//         <div className='hidden lg:block'><img src={mockup} alt=''/></div>
//     </div>
// </div>
