import React, { useState } from "react";
import Modal from "../Modal";
import ModalService from "../Modal/ModalService";
import Done from "../Done";
import { toast } from "react-toastify";
import axios from "axios";
import {FaTimes} from 'react-icons/fa'


function RequestDemo(props) {
  const [demoData, setDemoData] = useState({
    name: "",
    businessName: "",
    email: "",
  });
  const { name, businessName, email } = demoData;
  const requestDemo = async (props) => {
    if (!name.trim() || !businessName.trim() || !email.trim()) {
      toast.warn("Please fill all Fields");
    } else {
      const response = await axios.post(process.env.REACT_APP_WEBAPI_URL + "/website/demo", demoData)
      if(response?.data.status === "SUCCESS"){
        props.close();
        ModalService.open(Done);
      }
    }
  };
  return (
    <Modal>
      <div>
      
       <div  className="w-full flex items-start justify-between">
       <div>
       <p className="text-[#101213] font-[700] text-[24px] font-alegreya">
          Request Demo
        </p>
        <p className="mt-[24px] font-nunito font-[600] text-[16px] text-[#1F232E]">
          Enter your details and a link to the Demo would be sent to your page
        </p>
       </div>
       <div className="cursor-pointer" onClick={props.close}>
          <FaTimes className="text-[25px]"/>
        </div>
       </div>

        <form action="" className="mt-[24px] space-y-[24px]">
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-[#101213] font-[400] font-nunito text-[16px] "
            >
              Tell us your name
            </label>
            <input
              type="text"
              className="mt-[16px] border border-[#E3E5E8] h-[50px] rounded-[12px] placeholder:font-nunito px-[10px] outline-none"
              placeholder="Name"
              onChange={(e) =>
                setDemoData({ ...demoData, name: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-[#101213] font-[400] font-nunito text-[16px] "
            >
              Tell us your Business Name
            </label>
            <input
              type="text"
              className="mt-[16px] border border-[#E3E5E8] h-[50px] rounded-[12px] placeholder:font-nunito px-[10px] outline-none"
              onChange={(e) =>
                setDemoData({ ...demoData, businessName: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-[#101213] font-[400] font-nunito text-[16px] "
            >
              Tell us your Email
            </label>
            <input
              type="email"
              required
              className="mt-[16px] border border-[#E3E5E8] h-[50px] rounded-[12px] placeholder:font-nunito px-[10px] outline-none"
              onChange={(e) =>
                setDemoData({ ...demoData, email: e.target.value })
              }
            />
          </div>
          <div className="flex items-center justify-end w-full">
            <button
              type="button"
              className="bg-[#013CE4] text-white font-nunito font-[700] p-[12px_16px] rounded-[12px]"
              onClick={() => requestDemo(props)}
            >
              Request demo
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default RequestDemo;
