import React from 'react'
import {BsArrowRight} from 'react-icons/bs'
import { Link } from 'react-router-dom'
function Interest() {
  return (
    <div className='flex flex-col items-center justify-center w-full p-[20px] lg:p-[50px] my-[40px] lg:my-[80px]'>
        <p className='text-[32px] lg:text-[48px] leading-[36px] lg:leading-[56px] font-[700] font-alegreya text-center'>Interested in building the <br className='hidden lg:block' /> future of commerce with us?</p>
        <p className='mt-[40px] font-[400] font-nunito text-[16px] leading-[24px] text-center'>We would be delighted to work with you.</p>
        <Link to='/contact-us'><button className='flex items-center bg-primary text-white font-nunito space-x-[8px] p-[12px_16px] rounded-[12px] mt-[40px]'>
            <span className='pl-[8px]'>Contact us</span>
            <BsArrowRight/>
        </button></Link>
    </div>
  )
}

export default Interest