import React from 'react'
import me from "../../assets/me.webp";
import tolu from "../../assets/tolu.webp";
import mogbi from "../../assets/mogbi.webp";
// import sam from "../../assets/sam.webp";
import ojo from "../../assets/ojo.webp";
import tobi from "../../assets/tobi.webp";
function Team() {
  const teamMembers = [
    {
      id: 1,
      name: 'Mogbitse Okitikpi',
      position: 'Product',
      image: mogbi
    },
   
    // {
    //   id: 2,
    //   name: 'Samuel Odeniyi',
    //   position: 'Product',
    //   image: sam
    // },
    {
      id: 3,
      name: 'Ligali Titobioluwa',
      position: 'Engineering',
      image: tobi
    },
    {
      id: 4,
      name: 'Ojo Ilesanmi',
      position: 'Engineering',
      image: ojo
    },
    {
      id: 5,
      name: 'Tolulope Ogunnusi',
      position: 'Engineering',
      image: tolu
    },
    
    {
      id: 6,
      name: 'Aniebetabasi Obo',
      position: 'Engineering',
      image: me
    },
   
  ]

  return (
    <div className='p-[20px] lg:p-[50px]'>
      <p className='font-alegreya text-[32px] lg:text-[48px] font-[700] leading-[36px] lg:leading-[52px]'>Meet the Team</p>
      <div className='grid grid-cols-3 lg:grid-cols-5 mt-[30px] lg:mt-[60px] gap-[26px]'>
       {
        teamMembers.map(({id, name, position, image})=>(
          <div className='flex flex-col items-center justify-center' key={id}>
          <div className='h-[74px] lg:h-[146px] w-[74px] lg:w-[146px] rounded-full bg-[#D9D9D9]'>
            <img src={image} alt="" className='rounded-full' />
          </div>
          <div className='flex flex-col items-center justify-center mt-[12px]'>
            <p className='font-alegreya font-[700] text-[10px] lg:text-[20px] leading-[13px] lg:leading-[27px]'>{name}</p>
            <p className='font-[400] font-nunito text-[8px] lg:text-[16px] leading-[10px] lg:leading-[20px] mt-[12px]'>{position}</p>
          </div>
        </div>
        ))}
       
      </div>
          </div>
  )
}

export default Team