import React, {useState} from 'react'
import logoIcon from "../../assets/logoIcon.webp";
import logoIcon2 from "../../assets/logoIcon2.webp";
import contactLogo from "../../assets/contactLogo.webp";
import axios from "axios";
import { toast } from "react-toastify";

function Contact() {
  // const [firstname, setName] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [content, setContent] = useState("");

 
  const sendFeedBack = async (e) => {
    e.preventDefault();
    if ( !content.trim() && !email.trim() && !businessName.trim()) {
      toast.warn("Please Fill In the Fields");
    } else {
      const data = { email, content , businessName};
      axios
        .post(process.env.REACT_APP_API_URL + "/auth/contact-us", data)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Feedback Sent Successfully");
            setContent("");
            setEmail("");
            setBusinessName("");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };
  return (
    <div className='p-[20px] lg:p-[40px_50px] grid lg:grid-cols-2 min-h-[645px] gap-x-[140px]'>
      <div className='hidden lg:flex bg-[#F7F7F8] relative h-full flex-col  items-start justify-between'>
        <div className='flex items-center justify-between w-full'>
          <img src={logoIcon} alt="" />
          <img src={contactLogo} alt="" />
        </div>
        <div className='px-[32px]'>
          <p className='text-[#013CE4] font-[700] font-alegreya text-[55px] leading-[50px]'>Bridging Community, <br /> Commerce and <br /> Finance</p>
        </div>
        <div className='flex items-center justify-end w-full'>
          <img src={logoIcon2} alt="" />
        </div>
      </div>
      <div>
      <p className='font-alegreya font-[700] text-[32px] lg:text-[48px] text-[#101213] leading-[36px] lg:leading-[52px]'>We are glad you <br /> reached out!</p>
      <p className='mt-[16px] lg:mt-[32px] font-nunito text-[16px] leading-[24px]'>You would get a reply from us as soon as we get your message.</p>
      <form action="" className='mt-[24px] lg:mt-[32px] space-y-[32px]' onSubmit={sendFeedBack}>
        <div className='flex flex-col'>
          <label htmlFor="name" className='text-[16px] font-nunito font-[400] leading-[24px]'>Tell us your Business Name</label>
          <input type="text" 
          onChange={(e)=> setBusinessName(e.target.value)}
          className='text-[#000000] font-nunito h-[48px] mt-[16px] outline-none border px-[10px] border-[#E3E5E8] rounded-[12px]' placeholder='Business Name'/>
        </div>
       
        <div className='flex flex-col'>
          <label htmlFor="email" className='text-[16px] font-nunito font-[400] leading-[24px]'>Email  Address</label>
          <input type="email" 
          onChange={(e)=> setEmail(e.target.value)}
          className='text-[#000000] font-nunito h-[48px] mt-[16px] outline-none border px-[10px] border-[#E3E5E8] rounded-[12px]' placeholder='Email'/>
        </div>
        {/* <div className='flex flex-col'>
          <label htmlFor="email" className='text-[16px] font-nunito font-[400] leading-[24px]'>Business Name</label>
          <input type="text" 
          onChange={(e)=> setBusinessName(e.target.value)}
          className='text-[#000000] font-nunito h-[48px] mt-[16px] outline-none border px-[10px] border-[#E3E5E8] rounded-[12px]' placeholder='Business Name'/>
        </div> */}
        <div className='flex flex-col'>
          <label htmlFor="message" className='text-[16px] font-nunito font-[400] leading-[24px]'>Type in your message</label>
          <textarea type="text" 
          onChange={(e)=> setContent(e.target.value)}
           className='text-[#000000] font-nunito h-[210px] mt-[16px] outline-none border p-[10px] border-[#E3E5E8] rounded-[12px]' placeholder='Hello, Crosspoint...'></textarea>
        </div>
        <button className='bg-primary p-[12px_30px] font-nunito font-[700] text-white rounded-[12px] w-full lg:w-fit' type='submit'>Send</button>
      </form>
      </div>
    </div>
  )
}

export default Contact