import React from 'react'
import { BsArrowRightShort } from "react-icons/bs";
import procure from "../../assets/procure.png";
import detol from '../../assets/detol.png'
import fair from '../../assets/fair.png'
import print from '../../assets/printer.png'
import date from '../../assets/date.png'
import ModalService from '../Modal/ModalService';
import JoinBeta from '../JoinBeta';
function Procurement() {
    const joinBeta = () => {
        ModalService.open(JoinBeta)
      }
  return (
   <div className='bg-[#FFFFFF] p-[20px] lg:p-[40px_90px] mt-[12px] lg:mt-[50px]'>
    <div>
    <p className='font-[800] font-nunito text-[20px] lg:text-[56px] leading-[24px] lg:leading-[80px]'>Why Use Crosspoint?</p>
        <div className='bg-[#FFFFFF] grid lg:grid-cols-2   mt-[12px] lg:mt-[48px] z-1 shadow-lg shadow-blue-200/50 p-[20px] lg:p-[40px_60px] rounded-3xl gap-[40px]  border-[4px] border-[#F7F7F8]'>
            <div className='flex flex-col items-start justify-center w-full'>
                
                <h1 className='font-[700] text-[#101213] text-[20px] lg:text-[48px] leading-[24px] lg:leading-[56px] mt-[30px] font-nunito'>Achieve your financial goals
                </h1>
                <p className='font-nunito text-[14px] lg:text-[24px] text-[#101213] mt-[40px] '>Optimize your spending and achieve your goals through our embedded finance solutions
                </p>
                <button className="font-nunito text-white font-[700] text-[14px] bg-primary p-[12px_16px] flex items-center mt-[20px] rounded-[12px]" onClick={joinBeta}>
            Get Started
            <BsArrowRightShort className="text-[25px]" />
          </button>
            </div>
            <div className='hidden lg:block'><img src={procure} alt=''/></div>
        </div>
    </div>
    <div className='mt-[12px] lg:mt-[66px] grid lg:grid-cols-2 gap-[12px] lg:gap-[66px]'>
    {
      businessCardData.map(({id, img, text})=> (
        <div className='w-full lg:w-full h-fit lg:h-[550px] rounded-2xl p-[20px] bg-[#FFFFFF] z-1 shadow-lg shadow-blue-200/50 border-[4px] border-[#F7F7F8]' key={id}>
        <img src={img} alt=''/>
        <p className='text-[20px] lg:text-[32px] font-[700] font-nunito text-[#101213] mt-[24px]'>{text}</p>
    </div>
      ))
    }
    </div>
   </div>
  )
}

export default Procurement





const businessCardData = [
    {
        id: 1,
        img: detol,
        text: 'Never run out of stock on business supplies'
    },

    {
        id: 2,
        img: fair,
        text: 'Fair, stable prices, all year round with deposit options'
    },

    {
        id: 3,
        img: print,
        text: 'Product insurance is guaranteed'
    },

    {
        id: 4,
        img: date,
        text: 'Buy more with less'
    },
]